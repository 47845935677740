@import "../../scss/variables";

ul.resp-tabs-list {
  margin: 0px;
  padding: 0px;
}

.resp-tabs-list li {
  font-weight: 600;
  font-size: 13px;
  display: inline-block;
  padding: 4px 10px;
  margin: 0 4px 0 0;
  list-style: none;
  cursor: pointer;
  float: left;
}

.resp-tabs-container {
  padding: 0px;
  background-color: transparent;
  clear: left;
}

h2.resp-accordion {
  cursor: pointer;
  padding: 5px;
  display: none;
}

.resp-tab-content {
  display: none;
  padding: 20px 20px 0 20px;
  padding-bottom: 0;
}

.resp-tab-active {
  border-bottom: none;
  background-color: #fff;
}

.resp-content-active, .resp-accordion-active {
  display: block;
}

.resp-tab-content {
  border: 1px solid #c1c1c1;
  border-top-color: #8645ff;
}

h2 {
  &.resp-accordion {
    font-size: 13px;
    border: 1px solid #c1c1c1;
    border-top: 0px solid #c1c1c1;
    margin: 0px;
    padding: 10px 15px;
  }

  &.resp-tab-active {
    border-bottom: 0px solid #c1c1c1 !important;
    margin-bottom: 0px !important;
    padding: 10px 15px !important;
  }

  &.resp-tab-title:last-child {
    border-bottom: 12px solid #c1c1c1 !important;
    background: blue;
  }
}

/*-----------Vertical tabs-----------*/

.resp-vtabs {
  ul.resp-tabs-list {
    float: none;
    margin-top: 0 !important;
    color: #fff;
    padding-top: 34px;
  }

  .resp-tabs-list li {
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: relative;
    display: inline-block;
    margin: 7px 15px;
    padding: 3px 9px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    vertical-align: middle;
    background: rgba(255, 255, 255, 0.18);
  }

  .resp-tabs-container {
    padding: 0px;
    background-color: transparent;
    clear: none;
    border-bottom: 0 !important;
  }

  .resp-tab-content {
    border: none;
    word-wrap: break-word;
    height: 100%;
  }

  li {
    &.resp-tab-active {
      position: relative;
      border-right: 0 !important;
      background: transparent;
    }

    &.active {
      border-right: 0 !important;
      color: #fff;
    }
  }

  .resp-tab-active:hover {
    border-right: 0 !important;
    color: #fff;
  }
}

.resp-arrow {
  width: 0;
  height: 0;
  float: right;
  margin-top: 3px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 12px solid #c1c1c1;
}

h2.resp-tab-active {
  span.resp-arrow {
    border: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 12px solid #9B9797;
  }

  background: #DBDBDB;

  /* !important;*/
}

/*-----------Accordion styles-----------*/

.resp-easy-accordion {
  h2.resp-accordion {
    display: block;
  }

  .resp-tab-content {
    border: 1px solid #c1c1c1;

    &:last-child {
      border-bottom: 1px solid #c1c1c1;

      /* !important;*/
    }
  }
}

.resp-jfit {
  width: 100%;
  margin: 0px;
}

.resp-tab-content-active {
  display: block;
}

h2.resp-accordion:first-child {
  border-top: 1px solid #c1c1c1;

  /* !important;*/
}



.resp-vtabs .first-sidemenu li.active {
  background: rgba(0, 0, 0, 0.2) !important;
}


.slide.submenu .slide-item.active:before {
  background: transparent;
}

.side-account li {
  padding: 7px;
  font-size: 14px;
  color: #191919;
  list-style-type: none;
}